import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Cookies from "js-cookie";
// core components
import ReactGA from "react-ga4";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
//import Parallax from "components/Parallax/Parallax";

const useStyles = makeStyles(styles);

export default function AlgemeneVoorwaardenPage() {
  if (Cookies.get("rcl_statistics_consent") === true) {
    ReactGA.send("pageview");
  }
  const classes = useStyles();
  return (
    <div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <br />
          <br />
          <br />
          <p>
            <h2>Algemene voorwaarden IBB KEMPEN BV</h2>
          </p>
          <p>
            <h3>Artikel 1: Toepassingsgebied</h3>
          </p>
          <p>
            1. Deze algemene voorwaarden zijn van toepassing op alle documenten
            uitgaande van en alle contractuele relaties aangegaan door IBB
            KEMPEN BV, een besloten vennootschap naar Belgisch recht, met zetel
            gevestigd teMechelsestraat 2, 2260 Westerlo en ingeschreven in de
            Kruispuntbank van Ondernemingen onder het ondernemingsnummer
            0739.577.389 (hierna &ldquo;<strong>IBB</strong>&rdquo;), met de
            cli&euml;nt, inclusief iedere aparte opdracht besteld door de
            cli&euml;nt.{" "}
          </p>
          <p>
            2. Elke ondertekening van een opdrachtbrief vanwege de cli&euml;nt
            dan wel iedere aparte opdracht besteld door de cli&euml;nt geldt als
            aanvaarding van onderhavige algemene voorwaarden.{" "}
          </p>
          <p>
            3. Elke afwijking moet door beide partijen uitdrukkelijk
            schriftelijk worden aanvaard.{" "}
          </p>
          <p>
            4. In geval van discrepantie tussen een bepaling van de
            opdrachtbrief en een bepaling van deze algemene voorwaarden, hebben
            de bepalingen van de opdrachtbrief voorrang doch enkel voor het
            gedeelte dat uitdrukkelijk afwijkt van huidige algemene voorwaarden.
          </p>
          <p>
            5. De cli&euml;nt erkent uitdrukkelijk dat deze algemene voorwaarden
            in de toekomst kunnen wijzigen. In dat geval zal IBB de cli&euml;nt
            de aangepaste algemene voorwaarden bezorgen. Vanaf ontvangst erkent
            de cli&euml;nt de op dat ogenblik bestaande algemene voorwaarden te
            hebben gelezen, aanvaard en goedgekeurd te hebben indien de
            cli&euml;nt geen bezwaar uit binnen de 5 (vijf) werkdagen na
            ontvangst, behoudens in geval van substanti&euml;le wijzigingen, in
            welk geval de klant het recht zal hebben de overeenkomst op te
            zeggen.
          </p>
          <p>
            <h3>Artikel 2: Opdrachtbrieven</h3>
          </p>
          <p>
            1. De opdrachtbrieven van IBB zijn geheel vrijblijvend en zijn
            slechts bindend na schriftelijke ondertekening door de cli&euml;nt.
            De in de opdrachtbrief vervatte prijzen zijn slechts geldig conform
            de geldingsduur erin opgenomen. Bij gebreke aan vermelding van enige
            geldingsduur, gelden de prijzen gedurende &eacute;&eacute;n (1)
            jaar.
          </p>
          <p>
            2. De opdrachtbrieven van IBB zijn ondeelbaar en slechts geldig in
            hun totaliteit.{" "}
          </p>
          <p>
            3. Louter de prestaties die expliciet in de opdrachtbrief worden
            vermeld maken er deel van uit. Bijkomend gevraagde prestaties zullen
            steeds voorafgaand moeten worden goedgekeurd door IBB en zullen
            steeds bijkomend worden gefactureerd.{" "}
          </p>
          <p>
            4. Indien de beroepsbeoefenaar het forfaitair ereloon overeenkomstig
            de opdrachtbrief verhoogt, komen de Partijen uitdrukkelijk overeen
            dat dit een geldige reden vormt voor de eenzijdige aanpassing van de
            overeenkomst door de beroepsbeoefenaar vanaf het relevante tijdstip.{" "}
          </p>
          <p>
            5. De kosten en erelonen zijn vastgesteld overeenkomstig de geldende
            wettelijke en reglementaire bepalingen die van toepassing zijn op de
            beroepsbeoefenaar en voorzien in de overeenkomst.
          </p>
          <p>
            6. De vertegenwoordiger van de cli&euml;nt verbindt zich ten aanzien
            van de beroepsbeoefenaar om hoofdelijk en ondeelbaar gehouden te
            zijn tot betaling van de erelonen, kosten en vergoedingen van de
            beroepsbeoefenaar.
          </p>
          <p>
            <h3>Artikel 3: Totstandkoming, duur en be&euml;indiging</h3>
          </p>
          <p>
            1. De loutere ondertekening van de opdrachtbrief dan wel betaling
            van een factuur door de cli&euml;nt geldt als aanvaarding van deze
            algemene voorwaarden. De overeenkomst tussen de cli&euml;nt en IBB
            wordt steeds geacht ontstaan te zijn op de zetel van IBB. Er zal in
            geen geval een overeenkomst op afstand in de zin van Boek VI van het
            Wetboek Economisch Recht tot stand komen.
          </p>
          <p>
            2. Onder &lsquo;recurrente opdracht&rsquo; wordt verstaan: alle
            opdrachten die terugkerend zijn en die niet voldoen aan de definitie
            van &lsquo;niet-recurrente opdrachten&rsquo; in punt 3 hieronder.
          </p>
          <p>
            Behalve bij een andersluidende bepaling in de opdrachtbrief wordt de
            overeenkomst voor een recurrente opdracht geacht voor onbepaalde
            duur te zijn aangegaan.
          </p>
          <p>
            Beide partijen kunnen op elk ogenblik dit deel van de overeenkomst
            be&euml;indigen door middel van een opzegging die via e-mail ter
            kennis wordt gebracht met inachtneming van een opzegtermijn van 3
            (drie) maanden.
          </p>
          <p>
            Tijdens de opzeggingsperiode blijven de bepalingen van de
            opdrachtbrief en deze algemene voorwaarden van kracht.
          </p>
          <p>
            Tenzij de partijen anders beslissen, heeft de be&euml;indiging van
            de overeenkomst, zelfs indien een opzeggingstermijn in acht wordt
            genomen, de onmiddellijke stopzetting tot gevolg van de diensten van
            de beroepsbeoefenaar voor de voorbereiding van de jaarrekening van
            het boekjaar dat voorafgaat aan de be&euml;indigingsdatum.
          </p>
          <p>
            3. Niet-recurrente opdrachten zijn eenmalige en niet-terugkerende
            opdrachten waarvan de uitvoering een einde maakt aan de opdracht.
            Behoudens tegenbewijs wordt de overeenkomst met betrekking tot een
            niet-recurrente opdracht geacht voor een bepaalde duur te zijn
            gesloten.
          </p>
          <p>
            Ze eindigt door de uitvoering van de opdracht en, indien dit van
            toepassing is gelet op de aard van de opdracht, door de uitvoering
            van de overeengekomen diensten.
          </p>
          <p>
            De cli&euml;nt heeft op grond van artikel 1794 O.B.W. en
            desgevallend in afwijking van artikel 2004 O.B.W. het recht om de
            overeenkomst vroegtijdig te be&euml;indigen mits hij de
            beroepsbeoefenaar:
          </p>
          <ul>
            <li>
              <p>
                alle kosten en erelonen betaalt die overeenstemmen met de reeds
                uitgevoerde werkzaamhedenen;
              </p>
            </li>
            <li>
              <p>
                alles betaalt wat de beroepsbeoefenaar bij de uitvoering van de
                opdracht bijkomend had kunnen verdienen.
              </p>
            </li>
          </ul>
          <p>
            Deze vergoeding wordt op werkelijke basis berekend, met een minimum
            van 25% van de erelonen die verschuldigd waren geweest in geval van
            volledige uitvoering van de opdracht.
          </p>
          <p>
            4. In alle gevallen kan de beroepsbeoefenaar de overeenkomst op elk
            ogenblik zonder opzeggingstermijn en zonder vergoeding
            be&euml;indigen, indien er redenen zijn die de voortzetting van de
            samenwerking onmogelijk maken, zoals:
          </p>
          <ul>
            <li>
              <p>
                omstandigheden die de onafhankelijkheid van de beroepsbeoefenaar
                in het gedrang brengen;
              </p>
            </li>
            <li>
              <p>
                omstandigheden die de uitvoering van de opdracht conform de
                beroeps- en deontologische normen onmogelijk maken;
              </p>
            </li>
            <li>
              <p>
                (een) kennelijke tekortkoming(en) van de cli&euml;nt ten aanzien
                van zijn eigen verplichtingen zoals omschreven in deze algemene
                voorwaarden en in de opdrachtbrief, die door de cli&euml;nt niet
                wordt rechtgezet binnen de 14 (veertien) dagen na hiertoe door
                de beroepsbeoefenaar in gebreke te zijn gesteld;
              </p>
            </li>
            <li>
              <p>
                staking van betaling, geschokte kredietwaardigheid,
                faillissement, of een andere vorm van bescherming tegen
                schuldeisers in hoofde van de cli&euml;nt;
              </p>
            </li>
            <li>
              <p>
                In geval van overlijden van de cli&euml;nt. De werkzaamheden
                kunnen desgevallend worden hernomen teneinde de verplichtingen
                van de overledene te vervullen mits schriftelijke bevestiging
                van alle erfgenamen.
              </p>
            </li>
          </ul>
          <p>
            Wanneer de beroepsbeoefenaar de overeenkomst be&euml;indigt om (een)
            bepaalde reden(en), dan deelt hij schriftelijk aan de cli&euml;nt de
            reden voor de be&euml;indiging mee en informeert hij deze van de
            rechtshandelingen die dringend en noodzakelijk moeten worden gesteld
            ter vrijwaring van zijn rechten, en waarvoor hij opdracht had
            gekregen.
          </p>
          <p>
            5. De cli&euml;nt kan de overeenkomst op elk moment zonder opzegging
            noch vergoeding be&euml;indigen wanneer de beroepsbeoefenaar
            kennelijk in gebreke blijft bij het uitvoeren van zijn
            verplichtingen, zoals beschreven in deze algemene voorwaarden en
            desgevallend in de opdrachtbrief en deze tekortkoming niet rechtzet
            binnen de 14 (veertien) dagen na hiertoe door de cli&euml;nt in
            gebreke te zijn gesteld.
          </p>
          <p>
            6. Bij het einde van de overeenkomst worden alle boeken en
            documenten, die eigendom zijn van de cli&euml;nt, ter beschikking
            gesteld van de cli&euml;nt of zijn mandataris middels het opmaken
            van een gedateerde en ondertekende inventaris in evenveel originele
            exemplaren als er partijen zijn. Het einde van de opdracht:
          </p>
          <ul>
            <li>
              <p>
                leidt tot de onmiddellijke intrekking van alle mandaten die zijn
                verleend voor de indiening van de belastingaangiftes in de PB,
                Ven.B. en btw-aangiftes, neerlegging van de jaarrekening of
                vertegenwoordiging (bv. elektronisch mandaat voor toegang tot
                het UBO-register, ....);
              </p>
            </li>
            <li>
              <p>
                vereist dat de cli&euml;nt al zijn boekhoudkundige stukken van
                de beroepsbeoefenaar onmiddellijk terugneemt.
              </p>
            </li>
          </ul>
          <p>
            7. De beroepsbeoefenaar is in alle omstandigheden gerechtigd op de
            betaling van de erelonen en kosten m.b.t. de reeds verrichte
            werkzaamheden.
          </p>
          <p>
            8. De vervanging van de verantwoordelijke voor de uitvoering van de
            opdracht kan door de cli&euml;nt in geen geval worden ingeroepen als
            wettige grond voor be&euml;indiging van de overeenkomst.
          </p>
          <p>
            <h3>Artikel 4: Uitvoeringstermijn</h3>
          </p>
          <p>
            1. Uitvoeringsdata worden door IBB steeds louter ten titel van
            inlichting verstrekt en zijn geenszins bindend, tenzij het
            wettelijke termijnen betreft. Geen enkele vertraging in de
            uitvoering kan aanleiding geven tot ontbinding ten laste van IBB,
            noch tot welke schadevergoeding dan ook, behalve indien de
            uitvoeringstermijn intentioneel niet werd nageleefd of indien de
            laattijdigheid het uitsluitende gevolg is van een grove nalatigheid
            in hoofde van IBB, of indien er sprake is van een beroepsfout in
            hoofde van IBB.
          </p>
          <p>
            <h3>Artikel 5: Prijzen en wijzigingen</h3>
          </p>
          <p>
            1. Behoudens expliciet andersluidende bepaling, zijn alle prijzen,
            kortingen en voorwaarden, vermeld op de facturen en opdrachtbrieven
            van IBB uitgedrukt in EURO en steeds exclusief BTW, en andere taksen
            en kosten die door de cli&euml;nt verschuldigd zijn.
          </p>
          <p>
            2. Elke verhoging van het BTW-tarief of van een enige andere
            belasting, van welke aard ook, is voor rekening van de cli&euml;nt
            (inclusief grote koerswisselingen zowel van binnenlandse als
            buitenlandse valuta, verhoging sociale lasten, etc.).
          </p>
          <p>
            3. Veranderingen aan een opdracht, van welke aard ook, kunnen
            oorzaak zijn van een prijsverhoging en/of verlenging van de
            uitvoeringstermijnen.
          </p>
          <p>
            4. Eventuele kortingen worden steeds geacht eenmalig te zijn
            verleend. Eerdere verleende kortingen binden IBB in geen geval voor
            een latere overeenkomst.{" "}
          </p>
          <p>
            <h3>Artikel 6: Verplichtingen van de partijen</h3>
          </p>
          <p>1. De cli&euml;nt verbindt er zich toe:</p>
          <ul>
            <li>
              <p>
                IBB alle documenten, gegevens en informatie die nodig zijn voor
                de uitvoering van de opdracht tijdig ter beschikking te stellen,
                ook indien deze informatie afkomstig is van derden. Deze
                informatie en documentatie is steeds inhoudelijk correct
                &egrave;n volledig. Op eenvoudig verzoek van IBB bevestigt de
                cli&euml;nt schriftelijk dat de aangeleverde
                gegevens/documenten/inlichtingen volledig en correct zijn;
              </p>
            </li>
            <li>
              <p>
                de noodzakelijke gegevens op een correct geordende en
                geklasseerde wijze aan te leveren;
              </p>
            </li>
            <li>
              <p>
                de voorbereidende werkzaamheden die desgevallend op basis van de
                aanvaarde opdrachtbrief moeten uitgevoerd worden, tijdig en
                correct te verrichten;{" "}
              </p>
            </li>
            <li>
              <p>
                elk gegeven, elke gebeurtenis of ontwikkeling die enige invloed
                zou kunnen hebben op de uitvoering van de opdracht steeds
                onmiddellijk en schriftelijk ter kennis te brengen van IBB;
              </p>
            </li>
            <li>
              <p>
                na te gaan of de door IBB opgestelde documenten en verklaringen
                overeenstemmen met zijn verwachtingen en met de door hem
                verschafte informatie, en indien dit niet het geval is, IBB
                hiervan onverwijld op de hoogte te brengen. Indien de
                cli&euml;nt dit niet doet, wordt IBB vrijgesteld van elke
                verantwoordelijkheid voor het niet-naleven van de wettelijk
                opgelegde termijnen, regelgeving en akkoorden voor de uitvoering
                van de fiscale, sociale of andere formaliteiten die onder zijn
                opdracht vallen;
              </p>
            </li>
            <li>
              <p>
                IBB correcte, nauwkeurige en bijgewerkte informatie te bezorgen,
                evenals elke latere wijziging hiervan die gepaard gaat met een
                eventuele aanpassing van de opdracht van IBB en in het bijzonder
                en zonder uitputtend te zijn, wat betreft de aanzienlijke
                wijziging van zijn financi&euml;le situatie, zijn geldmiddelen,
                zijn uiteindelijke begunstigden, en dit ten laatste binnen een
                termijn van 2 weken na kennisneming van deze informatie of
                wijziging ervan door de cli&euml;nt;
              </p>
            </li>
            <li>
              <p>
                IBB op de hoogte te brengen van elke wanbetaling, vanaf de
                eerste achterstallige betaling, ten aanzien van elke fiscale of
                sociale administratie of om het even welke andere schuldeiser.
              </p>
            </li>
          </ul>
          <p>
            2. Het niet, het laattijdig of het onbehoorlijk aanleveren van
            documenten en gegevens kan leiden tot de aanrekening van bijkomende
            erelonen en onkostenvergoedingen. Tevens kan IBB niet
            verantwoordelijk worden gesteld voor eventuele boetes als hierdoor
            de wettelijke termijnen niet kunnen gehandhaafd worden. IBB heeft
            het recht de uitvoering van haar werkzaamheden voor de cli&euml;nt
            op te schorten tot op het ogenblik dat de cli&euml;nt (opnieuw) aan
            de verplichtingen uit hoofde van de opdrachtbrief en deze algemene
            voorwaarden voldoet. Alle kosten en lasten (inclusief boetes en
            schadevergoedingen) voortvloeiend uit de opschorting of het uitstel
            zijn ten laste van de cli&euml;nt.
          </p>
          <p>
            3. De volgende termijnen moeten door de cli&euml;nt alleszins
            nageleefd worden voor de verstrekking van de documenten en
            inlichtingen:
          </p>
          <ul>
            <li>
              <p>
                Opdracht bestaande uit het opstellen van de BTW-aangiften: ten
                laatste de 5de dag volgend op het einde van de aangifteperiode;
              </p>
            </li>
            <li>
              <p>
                Opdracht bestaande uit het opstellen van de
                personenbelastingaangifte: ten laatste 30 juni van het
                aanslagjaar;
              </p>
            </li>
            <li>
              <p>
                Opdracht bestaande uit het opstellen van de jaarrekening: ten
                laatste 3 maanden na het einde van het boekjaar.
              </p>
            </li>
          </ul>
          <p>
            In het geval deze documenten niet tijdig worden overgemaakt, staat
            IBB niet meer garant voor een tijdige uitvoering en is de
            cli&euml;nt bijgevolg zelf aansprakelijk voor de daaruit
            voortvloeiende schade. Ook voor de maandaangiften moeten de
            noodzakelijke stukken telkens uiterlijk de 5de dag van de maand
            daaropvolgend aan IBB worden bezorgd.
          </p>
          <p>
            4. De uitvoering van de opdracht is niet specifiek gericht op het
            ontdekken van eventuele fraude.
          </p>
          <p>
            5. IBB houdt in het kader van de uitgevoerde werkzaamheden voor de
            cli&euml;nt een eigen (elektronisch) werkdossier aan van alle
            relevante stukken en gegevens. Dit impliceert dat alle relevante
            documenten worden gedigitaliseerd en toegevoegd aan het dossier van
            de cli&euml;nt. De originele documenten worden na digitalisatie
            terug bezorgd aan de cli&euml;nt die de bewaring van de documenten,
            zoals wettelijk voorgeschreven, ten laste neemt.
          </p>
          <p>
            6. IBB is tot het beroepsgeheim gehouden overeenkomstig de geldende
            wetgeving, onder voorbehoud evenwel van toepassing van de bepalingen
            van de wetgeving en reglementering tot voorkoming van het gebruik
            van het financi&euml;le stelsel voor het witwassen van geld en de
            financiering van terrorisme. Het beroepsgeheim heeft betrekking op
            alles waarvan IBB kennis krijgt naar aanleiding van of door de
            uitoefening van het beroep: alle schriftelijke dan wel mondelinge,
            vertrouwelijke mededelingen, de correspondentie, de
            telefoongesprekken, de uitgewisselde nota&rsquo;s evenals de
            correspondentie die in het kader van het toegekende mandaat aan
            derden wordt gestuurd. IBB is van haar beroepsgeheim ontheven in het
            kader van wetgeving tot voorkoming van het gebruik van het
            financi&euml;le stelsel voor het witwassen van geld en de
            financiering van terrorisme. De cli&euml;nt bevestigt dat hij op de
            hoogte is van het feit dat IBB onderworpen is aan de wet tot
            voorkoming van het gebruik van het financi&euml;le systeem voor het
            witwassen van geld en voor de financiering van terrorisme en hij
            verbindt zich ertoe om haar onmiddellijk alle informatie en/of
            documenten te bezorgen die vereist zijn in het kader van deze
            wetgeving. IBB zal haar opdracht slechts aanvatten nadat de
            cli&euml;nt alle documenten en informatie die vereist zijn in het
            kader van de antiwitwaswet heeft overgemaakt. Vallen daarnaast niet
            onder het beroepsgeheim: de neergelegde boekhoudstukken en de
            opgestelde en neergelegde jaarrekeningen evenals andere stukken die
            betrekking hebben op de verantwoording van de opstelling van de
            boekhouding.{" "}
          </p>
          <p>
            7. Gecertificeerde accountants en gecertificeerde belastingadviseurs
            zijn verplicht om aan de Cel voor Financi&euml;le
            Informatieverwerking alle feiten aan te geven die vastgesteld zijn
            in de uitvoering van hun beroepsactiviteit en waarvan ze weten of
            vermoeden dat ze te maken hebben met het witwassen van geld of de
            financiering van terrorisme; ze moeten de Cel inlichten als deze
            aanvullende vragen stelt op straffe van sanctionering als ze dit
            niet doen.{" "}
          </p>
          <p>
            8. IBB is verplicht tot geheimhouding tegenover derden die niet bij
            de uitvoering van de overeenkomst zijn betrokken. Deze geheimhouding
            betreft alle informatie van vertrouwelijke aard die door de
            cli&euml;nt is ter beschikking gesteld en de door verwerking daarvan
            verkregen resultaten. IBB is niet gerechtigd de informatie die door
            de cli&euml;nt ter beschikking wordt gesteld aan te wenden voor een
            ander doel dan waarvoor zij werd verkregen, tenzij voor anonieme,
            interne verwerking van cijfergegevens zoals geduid in haar privacy
            verklaring.{" "}
          </p>
          <p>
            9. IBB is gerechtigd de na bewerking verkregen cijfermatige
            uitkomsten &ndash; in de mate deze cijfermatige uitkomsten niet te
            herleiden zijn tot individuele opdrachtgevers &ndash; aan te wenden
            voor statistische of vergelijkende doeleinden
          </p>
          <p>
            10. Conform de wetgeving dient IBB bepaalde grensoverschrijdende
            fiscale constructies aan de belastingadministratie te melden. Indien
            IBB meent dat dergelijke meldplicht zich voordoet, zal zij de
            cli&euml;nt voorafgaand informeren. Indien de cli&euml;nt zich
            schriftelijk verzet tegen het voornemen dit te melden, dan zal IBB
            aangeven welke acties de cli&euml;nt zelf dient te ondernemen.
            Indien de cli&euml;nt vervolgens beslist geen actie te ondernemen,
            kan dit op geen enkel wijze de aansprakelijkheid van IBB in het
            gedrang brengen.
          </p>
          <p>
            <h3>Artikel 7: Klachten</h3>
          </p>
          <p>
            1. Eventuele klachten met betrekking tot de non-conformiteit van de
            werkzaamheden van IBB dan wel facturen van IBB dienen op
            gemotiveerde wijze, middels aangetekende zending, aan IBB te worden
            overgemaakt binnen een termijn van acht (8) kalenderdagen, te
            rekenen vanaf de oplevering van de werkzaamheden respectievelijk de
            datum van verzending van de factuur.
          </p>
          <p>
            2. Indien er binnen voormelde termijn geen reactie volgt, worden de
            werkzaamheden van IBB respectievelijk de factuur op onherroepelijke
            wijze als zijnde aanvaard beschouwd. Het simpelweg op ongemotiveerde
            wijze betwisten van de werkzaamheden dan wel het louter terugsturen
            van een factuur wordt in geen geval als klacht dan wel protest
            aanvaard. Voor zover een klacht niet binnen voormelde termijn en/of
            met inachtneming van de vormvoorschriften van artikel 7.1. wordt
            gesteld, zal dergelijke klacht door IBB als respectievelijk
            laattijdig dan wel niet-conform worden beschouwd. Zulke klacht
            verleent in geen geval recht op enige actie vanwege IBB.{" "}
          </p>
          <p>
            <h3>Artikel 8: Verzekering en Aansprakelijkheid</h3>
          </p>
          <p>
            1. Overeenkomstig de Wet van 17 maart 2019 betreffende de beroepen
            van accountant en belastingadviseur en de toepasselijke
            plichtenleer, heeft de beroepsbeoefenaar zijn burgerlijke
            beroepsaansprakelijkheid laten verzekeren met een
            beroepsaansprakelijkheidspolis die is goedgekeurd door het ITAA.
          </p>
          <p>
            2. De beroepsbeoefenaar waakt erover dat de diensten worden geleverd
            volgens de deontologische en andere beroepsnormen van het ITAA en
            rekening houdend met de relevante wetgeving en regelgeving die van
            kracht zijn op het tijdstip van de uitvoering van de overeenkomst.
            De beroepsbeoefenaar kan niet aansprakelijk worden gesteld voor de
            gevolgen van eventuele latere wijzigingen - desgevallend met
            terugwerkende kracht - van deze wets- en reglementsbepalingen.
            Bovendien kan de beroepsbeoefenaar niet aansprakelijk worden gesteld
            voor de beroepsfouten en vergissingen die v&oacute;&oacute;r de
            inwerkingtreding van de opdrachtbrief door eender wie zouden zijn
            begaan. Tot slot kan de beroepsbeoefenaar overeenkomstig het gemene
            recht alleen aansprakelijk worden gesteld voor opdrachten waarvan
            wordt aangetoond dat ze door hem zijn aanvaard. Aan de
            beroepsbeoefenaar en aan de cli&euml;nt wordt ook gevraagd om
            elkaar, en zoveel mogelijk schriftelijk, hun bezwaren, opmerkingen,
            aanbevelingen en adviezen te bezorgen.
          </p>
          <p>
            3. De beroepsbeoefenaar is enkel aansprakelijk voor de schade ten
            gevolge van:
          </p>
          <ul>
            <li>
              <p>
                zijn opzettelijke fout of die van een persoon voor wie hij moet
                instaan;
              </p>
            </li>
            <li>
              <p>
                zijn fout of die van een persoon voor wie hij moet instaan die
                het leven of de fysieke of psychische integriteit van een
                persoon aantast;
              </p>
            </li>
            <li>
              <p>
                het niet-uitvoeren van zijn essenti&euml;le verbintenissen,
                behoudens overmacht.
              </p>
            </li>
          </ul>
          <p>
            4. De (contractuele, extracontractuele of andere) aansprakelijkheid
            van de beroepsbeoefenaar voor de uitvoering van de opdracht is te
            allen tijde beperkt tot de verzekerde activiteiten en het bedrag
            waarop de door de beroepsbeoefenaar afgesloten
            beroepsaansprakelijkheidsverzekering betrekking heeft en binnen de
            grenzen van de dekking. Indien, om welke reden dan ook, de
            aansprakelijkheidsverzekeraar niet tot uitkering overgaat en de
            beroepsbeoefenaar toch gehouden zou zijn de schadevergoeding te
            betalen, is alle aansprakelijkheid per schadegeval beperkt tot
            5.000,00 EUR.
          </p>
          <p>
            5. De cli&euml;nt verbindt er zich toe en aanvaardt dat hij elke
            vordering in aansprakelijkheid die voortvloeit uit de overeenkomst
            uitsluitend kan instellen tegen de beroepsbeoefenaar binnen een
            termijn van 12 maanden te rekenen vanaf de dag waarop de cli&euml;nt
            de fout van de beroepsbeoefenaar heeft ontdekt, of had moeten
            ontdekken.
          </p>
          <p>
            6. Indien de beroepsbeoefenaar jegens de cli&euml;nt (dan wel jegens
            anderen ten behoeve waarvan diensten worden verleend) op grond van
            de overeenkomst aansprakelijk is voor schade waaraan ook andere
            personen hebben bijgedragen, dan is de beroepsbeoefenaar hiervoor
            niet hoofdelijk aansprakelijk. De aansprakelijkheid van de
            beroepsbeoefenaar is in dat geval beperkt tot het gedeelte van de
            totale schade dat aan de beroepsbeoefenaar toe te rekenen valt,
            gebaseerd op de mate waarin de aan de beroepsbeoefenaar toe te
            rekenen omstandigheden tot de schade hebben bijgedragen.
          </p>
          <p>
            7. Indien meerdere schadegevallen voortvloeien uit dezelfde fout,
            worden zij aanzien als &eacute;&eacute;n schadegeval, en wordt de
            aansprakelijkheid beperkt tot het hoogste bedrag van de bedragen die
            van toepassing zijn op de betrokken opdrachten of overeenkomsten.
          </p>
          <p>
            8. De beroepsbeoefenaar kan nooit aangesproken worden voor indirecte
            schade en/of gevolgschade, zoals doch niet beperkt tot (1) het
            verlies van baten, goodwill, cli&euml;nteel, handelsopportuniteiten
            of verwachte besparingen of voordelen, (2) het verlies of de
            beschadiging van gegevens, of (3) onrechtstreeks verlies of schade.
          </p>
          <p>
            9. De beroepsbeoefenaar is niet aansprakelijk voor eventuele
            verliezen, schade, kosten of uitgaven die op enigerlei wijze zouden
            ontstaan (1) uit (bedrieglijke) handelingen of nalatigheden,
            weglatingen, onjuiste of onvolledige verklaringen of onrechtmatige
            daden in hoofde van de cli&euml;nt, zijn bestuurders,
            aandeelhouders, lasthebbers, werknemers, tussenpersonen of
            onderaannemers, (2) wanneer de foutieve toepassing van de wettelijke
            en administratieve reglementen gebeurde op vraag of met medeweten
            van de cli&euml;nt of (3) wegens vertraging of het niet uitvoeren
            van zijn verplichtingen wanneer die vertraging of niet-uitvoering
            het gevolg is van omstandigheden die redelijkerwijs, buiten de
            controle van de beroepsbeoefenaar liggen. De beroepsbeoefenaar
            behoudt zich het recht voor om de eventuele schade op de cli&euml;nt
            te verhalen.
          </p>
          <p>
            10. De beroepsbeoefenaar kan bij niet tijdige overmaking van de
            documenten door de cli&euml;nt niet aansprakelijk gesteld worden
            voor de niet-naleving van de termijnen die opgelegd zijn in de wet,
            de reglementen en de akkoorden met betrekking tot de uitvoering van
            de fiscale, sociale of andere formaliteiten die binnen het bestek
            vallen van zijn opdracht.
          </p>
          <p>
            11. Voor zover wettelijk toegelaten, wordt iedere aansprakelijkheid
            van IBB, alsook van haar hulppersonen (waaronder bv. bestuurders (of
            hun vertegenwoordigers), werknemers, dienstverleners,
            (onder)aannemers, etc.) op grond van buitencontractuele
            aansprakelijkheid uitgesloten. Indien een vordering ingesteld zou
            worden op grond van de buitencontractuele aansprakelijkheid voor
            schade veroorzaakt door de niet-nakoming van een contractuele
            verbintenis tegen IBB en/of haar hulppersonen, kunnen deze alle
            verweermiddelen inroepen die voortvloeien uit de overeenkomst met de
            cli&euml;nt (en voor de hulppersoon ook deze uit diens overeenkomst
            met IBB). Deze beperking geldt niet bij vorderingen tot
            schadeloosstelling voor schade als gevolg van een aantasting van de
            fysieke of psychische integriteit of van een fout begaan met het
            opzet schade te veroorzaken.{" "}
          </p>
          <p>
            12. Indien de cli&euml;nt &eacute;&eacute;n van zijn verbintenissen
            onder deze overeenkomst niet nakomt en als gevolg hiervan een derde
            een vordering heeft ingesteld of dreigt in te stellen tegen IBB, dan
            zal de cli&euml;nt IBB schadeloos stellen en vrijwaren voor alle
            verlies, schade, uitgaven en aansprakelijkheid opgelopen door IBB,
            resulterend, voortvloeiend of verband houdend met de betreffende
            tekortkoming en vordering.{" "}
          </p>
          <p>
            <h3>Artikel 9: Facturatie en betaling</h3>
          </p>
          <p>
            1. Elke factuur van IBB is contant betaalbaar op de zetel van IBB of
            middels bankoverschrijving op het op de betrokken factuur vermelde
            rekeningnummer en uiterlijk binnen de 10 dagen, tenzij uitdrukkelijk
            anders vermeld op de facturen.
          </p>
          <p>
            2. Iedere op de vervaldag niet betaalde factuur zal van rechtswege,
            zonder voorafgaandelijke ingebrekestelling, worden verhoogd met een
            interestvoet van &eacute;&eacute;n procent (1%) per maand.
            Daarenboven wordt dergelijke factuur vermeerderd met een forfaitaire
            schadevergoeding bepaald op tien procent (10%) van het totale
            factuurbedrag, met een minimum van tweehonderdvijftig euro (250,00
            EUR), ter dekking van invorderings- en administratiekosten.
            Niettemin heeft IBB te allen tijde het recht een hogere vergoeding
            te vorderen, indien zij kan aantonen dat zij hogere schade heeft
            geleden. Is de cli&euml;nt een consument, dan is deze paragraaf niet
            van toepassing en geldt de wettelijke regeling.{" "}
          </p>
          <p>
            3. Laattijdige betaling van &eacute;&eacute;n (1) factuur heeft tot
            gevolg dat andere facturen, waarvoor in voorkomend geval een
            betalingstermijn werd verleend, zonder voorafgaandelijke
            ingebrekestelling onmiddellijk en van rechtswege in hun totaliteit
            opeisbaar zijn.{" "}
          </p>
          <p>
            4. In geval van wanbetaling is IBB gerechtigd haar
            diensten/prestaties op te schorten tot op het ogenblik van integrale
            betaling en zulks zonder enige verhaalsmogelijkheid vanwege de
            cli&euml;nt. Indien er sprake is van structurele wanbetaling, i.e.
            het door de cli&euml;nt in gebreke blijven gedurende acht (8)
            kalenderdagen na de schriftelijke ingebrekestelling tot betaling, is
            IBB gerechtigd om zonder bijkomende ingebrekestelling de
            overeenkomst als ontbonden te beschouwen ten laste van de
            cli&euml;nt.{" "}
          </p>
          <p>
            5. De beroepsbeoefenaar is niet verplicht kosten voor de cli&euml;nt
            aan derden voor te schieten (bvb. kosten aan de Kruispuntbank voor
            Ondernemingen, aan de Nationale Bank van Belgi&euml;, aan het
            Belgisch Staatsblad, registratierechten, ...). De cli&euml;nt is
            zelf en alleen verantwoordelijk voor het tijdig en volledig betalen
            van kosten aan derden. Indien de beroepsbeoefenaar evenwel beslist
            om de kosten voor de cli&euml;nt voor te schieten, gebeurt dit
            steeds ten eenmalige titel en onder elk voorbehoud. De cli&euml;nt
            zal op eerste verzoek de voorgeschoten kosten, in naam en voor diens
            rekening, terugbetalen aan de beroepsbeoefenaar.
          </p>
          <p>
            6. Partijen verklaren wederkerig dat de overeengekomen vergoedingen
            en schadevergoedingen geen onevenwicht doen ontstaan, niet
            onevenredig zijn aan het nadeel dat door de andere partij kan worden
            geleden, en niet te boven gaan aan de schade die zij bij aanvang van
            de overeenkomst konden vaststellen, in geval van wanprestatie door
            de andere partij.
          </p>
          <p>
            <h3>Artikel 10: Eigendom en bewaring van stukken</h3>
          </p>
          <p>
            1. IBB behoudt alle intellectuele eigendomsrechten op de door haar
            opgemaakte documenten, technische beschrijvingen, plannen,
            tekeningen, modellen, etc.
          </p>
          <p>
            2. De stukken, boeken en documenten mogen worden verplaatst. De
            beroepsbeoefenaar mag ze bijhouden voor de tijd die nodig is om zijn
            opdracht(en) uit te voeren. De cli&euml;nt heeft altijd het recht om
            ze te raadplegen, ofwel persoonlijk ofwel via (een) aangestelde(n)
            of gevolmachtigde(n), die in het bezit zijn van een schriftelijke
            volmacht, op voorwaarde dat deze stukken, boeken en documenten
            eigendom zijn van de cli&euml;nt.
          </p>
          <p>
            3. Het is de verantwoordelijkheid van de cli&euml;nt om de
            boekhoudstukken en -documenten te bewaren en ze terug te halen zodra
            de beroepsbeoefenaar hem meldt dat ze beschikbaar zijn. De
            beroepsbeoefenaar verbindt zich ertoe de boekhoudkundige stukken
            regelmatig ter beschikking van de cli&euml;nt te stellen.{" "}
          </p>
          <p>
            Boekhoudstukken en -documenten op papier worden opgehaald in het
            kantoor van de beroepsbeoefenaar, tenzij de beroepsbeoefenaar anders
            instemt.
          </p>
          <p>
            4. De kennisgeving aan de cli&euml;nt dat de boekhoudstukken
            en-documenten beschikbaar zijn legt de risico&rsquo;s bij hem van
            het verlies van de boekhoudstukken en-documenten.
          </p>
          <p>
            5. Als de cli&euml;nt nalaat zijn stukken op te halen ondanks de
            voorafgaande melding van de beroepsbeoefenaar heeft deze laatste het
            recht om de stukken en-documenten terug te sturen naar de
            cli&euml;nt op de wijze die hij nuttig acht en uitsluitend op kosten
            en onder de volledige verantwoordelijkheid van de cli&euml;nt. Deze
            mogelijkheid van de beroepsbeoefenaar doet geen afbreuk aan het feit
            dat de risico&rsquo;s bij de cli&euml;nt blijven vanaf de
            kennisgeving voorzien in de vorige paragraaf.
          </p>
          <p>
            <h3>Artikel 11: Persoonsgegevens</h3>
          </p>
          <p>
            1. In het kader van haar werkzaamheden verwerft IBB bepaalde
            persoonsgegevens. IBB zal dit steeds doen met inachtneming van de
            toepasselijke bepalingen rond de bescherming van persoonsgegevens
            (AVG, nationale wetten, etc.).
          </p>
          <p>
            2. Meer informatie over hoe IBB omgaat met persoonsgegevens, kan
            gevonden worden in de privacyverklaring van IBB, raadpleegbaar via{" "}
            <u>
              <Link to="/legal">
                https://integralebedrijfsbegeleiding.be/legal
              </Link>
            </u>{" "}
            .{" "}
          </p>
          <p>
            <h3>Artikel 12: Overmacht</h3>
          </p>
          <p>
            1. Wanneer een partij, wegens overmacht, in de onmogelijkheid
            verkeert om de overeenkomst uit te voeren heeft de andere partij het
            recht de overeenkomst te annuleren door eenvoudige schriftelijke
            kennisgeving aan de andere partij in geval van een situatie van
            overmacht die langer duurt dan 14 kalenderdagen. De cli&euml;nt noch
            IBB hebben in dat geval geen recht op schadevergoeding.
          </p>
          <p>
            2. IBB zal desgevallend in geval van overmacht in geen geval meer
            gebonden zijn aan enige uitvoeringstermijn zoals overeengekomen.
          </p>
          <p>
            3. Onder overmacht dient te worden verstaan: elke onvoorzienbare en
            onvermijdbare gebeurtenis onafhankelijk van de wil van een partij
            die een onoverkomelijk beletsel uitmaakt voor de nakoming van een
            verbintenis, staking, het niet kunnen verkrijgen van de nodige
            materialen en/of informatie, oorlog, brand, overstromingen
            terreuraanvallen, vertragingen of tekortkomingen door externen,
            overheidsvoorschriften, pandemie&euml;n, etc.
          </p>
          <p>
            <h3>Artikel 13: Nietigheid</h3>
          </p>
          <p>
            1. Indien &eacute;&eacute;n of meerdere artikelen van deze algemene
            voorwaarden &ndash; om eender welke reden &ndash; nietig zouden
            worden verklaard, blijven de overige bepalingen onverminderd hun
            rechtskracht behouden.
          </p>
          <p>
            2. IBB en de cli&euml;nt zullen ter vervanging van de nietige
            bepaling(en) in gemeen overleg naar een bepaling zoeken die zoveel
            als mogelijk het socio-economisch doel van die nietige bepaling(en)
            benadert.{" "}
          </p>
          <p>
            <h3>Artikel 14: Algemene bepalingen</h3>
          </p>
          <p>
            1. De cli&euml;nt verbindt zich ertoe om, hetzij direct hetzij
            indirect, geen personeel noch zelfstandige medewerkers van IBB aan
            te spreken met het oog op tewerkstelling, te werk te stellen of te
            rekruteren of op enige andere manier in dienst te nemen, noch
            personeelsleden en/of zelfstandige medewerkers van IBB ertoe aan te
            zetten om hun relatie met IBB te be&euml;indigen of de voorwaarden
            van hun overeenkomst met IBB in een voor IBB nadelige zin te
            wijzigen, op straffe van betaling van een forfaitaire
            schadevergoeding die gelijk is aan (i) het brutoloon van de
            betrokken personen voor het laatste volledige jaar indien het een
            personeelslid betreft of (ii) de vergoedingen betaald door IBB aan
            de zelfstandige medewerker in de 3 maanden voorafgaand aan de
            aanwerving. Dit beding is niet van toepassing indien IBB zelf een
            einde heeft gesteld aan de relatie met het
            personeelslid/zelfstandige medewerker.
          </p>
          <p>
            2. Partijen verbinden zich ertoe de commerci&euml;le en technische
            informatie en de bedrijfsgeheimen die zij vernemen van de andere
            partij en alle andere vertrouwelijke informatie, voorafgaand,
            gedurende en zelfs na de be&euml;indiging van de overeenkomst, met
            de grootst mogelijke geheimhouding te behandelen en enkel te
            gebruiken voor de uitvoering van de overeenkomst. Derden, die bij de
            uitvoering van de opdracht worden betrokken, zullen ten aanzien van
            deze feiten en omstandigheden afkomstig van de andere partij aan
            eenzelfde vertrouwelijke behandeling worden gebonden.
          </p>
          <p>
            3. De cli&euml;nt aanvaardt dat de overeenkomst met IBB in hoofde
            van IBB een inspanningsverbintenis is en geen resultaatsverbintenis,
            tenzij uitdrukkelijk anders overeengekomen (bv. naleving van
            wettelijke termijnen).
          </p>
          <p>
            4. IBB behoudt zich het recht voor om het geheel dan wel een
            gedeelte van de uitvoering van haar werkzaamheden aan derden toe te
            vertrouwen. De beroepsbeoefenaar mag zich tevens laten bijstaan door
            medewerkers of deskundigen van zijn keuze en de uit de overeenkomst
            voortvloeiende opdrachten geheel of gedeeltelijk laten uitvoeren
            door (een) aangestelde(n) of deskundige(n).
          </p>
          <p>
            5. Behoudens in geval van voorafgaandelijke schriftelijke instemming
            vanwege IBB, kan de cli&euml;nt de rechten en plichten die zij ten
            aanzien van IBB heeft, niet aan derden overdragen.
          </p>
          <p>
            6. De niet-uitoefening door IBB van een van haar rechten kan onder
            geen beding worden ge&iuml;nterpreteerd als een afstand van die
            rechten.{" "}
          </p>
          <p>
            <h3>
              Artikel 15: Toepasselijk recht en bevoegde rechtbanken
            </h3>
          </p>
          <p>
            1. Met uitsluiting van elke andere wetgeving, is uitsluitend het
            Belgische recht van toepassing op eventuele geschillen tussen IBB en
            de cli&euml;nt, met uitzondering van haar beginselen inzake
            internationaal privaatrecht.
          </p>
          <p>
            2. Alle geschillen, van welke aard ook, met inbegrip van de
            betwistingen aangaande de toepassing en de interpretatie van deze
            algemene voorwaarden, facturen dan wel enig ander bindend document
            dat tussen IBB en de cli&euml;nt wordt overeengekomen, die niet op
            minnelijke wijze tussen partijen wordt opgelost, behoren uitsluitend
            tot de bevoegdheid van de rechtbanken van het gerechtelijk
            arrondissement waarin de zetel van IBB is gevestigd, onverminderd
            het recht van IBB om de cli&euml;nt te dagvaarden voor de rechtbank
            bevoegd voor zijn/haar woonplaats dan wel zetel.
          </p>
          <br />
        </div>
      </div>
    </div>
  );
}

