import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import AlgemeneVoorwaardenPage from "views/AlgemeneVoorwaardenPage/AlgemeneVoorwaardenPage";

const Routes = () => {
  const ServicesPage = lazy(() => import("./views/ServicesPage/ServicesPage"));
  const AboutPage = lazy(() => import("./views/AboutPage/AboutPage"));
  const StanimalPage = lazy(() => import("./views/StanimalPage/StanimalPage"));
  const TipsAndTricksPage = lazy(() => import("./views/TipsAndTricksPage/TipsAndTricksPage"));
  const ContactPage = lazy(() => import("./views/ContactPage/ContactPage"));
  const TeamPage = lazy(() => import("./views/TeamPage/TeamPage"));
  const AppointmentPage = lazy(() =>
    import("./views/AppointmentPage/AppointmentPage")
  );
  const LegalPage = lazy(() => import("./views/LegalPage/LegalPage"));
  const LandingPage = lazy(() => import("./views/LandingPage/LandingPage"));
  const BillitPage = lazy(() => import("./views/BillitPage/BillitPage"));

  return (
    <Suspense
      fallback={
        <div
          style={{ height: "100vh", width: "100vw", backgroundColor: "#FFF" }}
        ></div>
      }
    >
      <Switch>
        {/*<Route path="/components" component={Components} />*/}
        <Route path="/diensten" render={() => <ServicesPage />} />
        <Route path="/over-ons" render={() => <AboutPage />} />
        <Route path="/team" render={() => <TeamPage />} />
        <Route path="/tipsandtricks" render={() => <TipsAndTricksPage />} />
        <Route path="/contact" render={() => <ContactPage />} />
        <Route path="/afspraken" render={() => <AppointmentPage />} />
        <Route path="/mybillit" render={() => <BillitPage />} />
        <Route path="/legal" strict render={() => <LegalPage />} />
        <Route path="/algemenevoorwaarden" render={() => <AlgemeneVoorwaardenPage />} />
        <Route path="/" render={() => <LandingPage />} />
      </Switch>
    </Suspense>
  );
};
export default Routes;
