import { main, mainRaised } from "assets/jss/material-kit-react";
import { container, title } from "assets/jss/material-kit-react.js";

const landingPageStyle = {
  main: {
    ...main,
  },
  mainRaised: {
    ...mainRaised,
  },
  container: {
    zIndex: "12",
    color: "#000",
    ...container,
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#1292dd",
    textDecoration: "none",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  description: {
    color: "#999",
  },
  alert: {
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 5000,
  },
  flex: {
    position: "relative",
    marginBlockEnd: "7rem",
    marginBlockStart: "7rem",
    paddingTop: "0rem",
  },
  introMS: {
    textAlign: "left",
    width: "100%",
  },
  breakoutImage: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
    objectPosition: "center top",
    top: "0",
    borderRadius: "15px",
    boxShadow:
      "0 12px 20px -10px rgba(18, 146, 221, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(18, 146, 221, 0.2)",
  },
  "@media (min-width: 576px)": {},
  "@media (min-width: 768px)": {},
  "@media (min-width: 992px)": {
    flex: {
      position: "relative",
      marginBlockEnd: "34rem",
      marginBlockStart: "7rem",
      paddingTop: "0rem",
    },
    introMS: {
      width: "44vw",
      maxWidth: "50rem",
      position: "absolute",
      left: "calc(-1 * (100vw - 110%) / 2)",
    },
    breakoutImage: {
      width: "45vw",
      maxWidth: "50rem",
      height: "25rem",
      objectFit: "cover",
      objectPosition: "center top",
      position: "absolute",
      right: "calc(-1 * (100vw - 110%) / 2)",
      top: "0",
    },
  },
  "@media (min-width: 1400px)": {
    introMS: {
      top: "3rem",
      left: "-4rem",
      width: "38rem",
    },
  },
  "@media (min-width: 1800px)": {
    breakoutImage: {
      right: "-20rem",
    },
  },
};

export default landingPageStyle;
